html {
  scroll-behavior: smooth;
}

.raya-info-banner {
  font-family: "Inter Tight", sans-serif;
  display:flex;
  align-items:center;
  justify-content: center;
  font-size: 16px;
  padding: 10.5px 0;
  color:#FFF;
  background-color: #21BF57;
}

@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .App {
    width: 100%;
    overflow-x: hidden; 
  }
}

@media only screen and (max-width: 1280px) {
  .desktop-view {
    display: none;
  }

  /* .raya-info-banner {
    display: none;
  } */

  .mobile-view {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .desktop-view {
    display: block;
  }

  .mobile-view {
    display: none;
  }
}

.text-raleway {
  font-family: 'Raleway';
}

.text-transform {
  text-transform: uppercase;
}

.text-blue-theme {
  color: #00334C;
}

.text-red-theme {
  color: #DC0046;
}

.text-white-theme {
  color: #FFFFFF;
}

.text-dark-gray-theme {
  color: #3A3A3A
}

.text-pink-theme {
  color: #F3386E
}

.text-italic {
  font-style: italic;
}

.meniaga-icon {
  height: 50px;
}

.meniaga-icon-manual {
  height: 50px;
}

.meniaga-icon-top {
  height: 40px;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-blue-theme {
  background-color: #00334C;
}

.bg-red-theme {
  background-color: #DC0046;
}

.container-red {
  border-radius: 10px;
  background: #DC0046;
  padding: 20px; 
}

.container-white {
  border-radius: 10px;
  background: #FFFFFF;
  padding: 20px; 
}

.privacy-terms-conditions {
  background-color: #FFFFFF;
  min-height: 100vh;
  padding: 0 100px 30px 100px;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1280px) {
  .privacy-terms-conditions {
    padding: 0 50px 30px 50px;
  }

  table {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .privacy-terms-conditions {
    padding: 0 100px 30px 100px;
  }
}

.first {
  background-color: #00334C;
  min-height: 100vh;
  padding: 30px 100px 50px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.first-mobile {
  background-color: #00334C;
  min-height: 100vh;
  padding: 0 50px 30px 50px;
  display: flex;
  flex-direction: column;
}

.ramadhan {
  background-color:#FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.alliance {
  background-color:#FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.alliance-mobile {
  background-color:#FFFFFF;
  min-height: 100vh;
  padding: 0 50px 30px 50px;
  display: flex;
  flex-direction: column;
}

.second {
  background-color: #FFFFFF;
  min-height: 100vh;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
}


.third {
  background-color: #00334C;
  min-height: 100vh;
  padding: 0 100px 150px 100px;
  display: flex;
  flex-direction: column;
}

.third-mobile {
  background-color: #00334C;
  min-height: 100vh;
  padding: 0 50px 150px 50px;
  display: flex;
  flex-direction: column;
}

.fourth {
  background-color: #FFFFFF;
  min-height: 90vh;
  padding: 0 100px 30px 100px;
  display: flex;
  flex-direction: column;
}

.fourth-mobile {
  background-color: #FFFFFF;
  min-height: 90vh;
  padding: 0 50px 30px 50px;
  display: flex;
  flex-direction: column;
}

.footer {
  padding: 0 100px 30px 100px;
  display: flex;
  flex-direction: row;
}

.footer-mobile {
  padding: 0 50px 30px 50px;
  display: flex;
  flex-direction: row;
}

.header {
  height: 10%;
}

.content {
  height: 90%;
}

.large-message {
  font-family: 'Raleway';
  font-size: 5rem;
  line-height: 1em;
  font-weight: 800;
}

.medium-message {
  font-family: 'Raleway';
  font-size: 30px;
  line-height: 1em;
}

.small-message {
  font-family: 'Raleway';
  font-size: 15px;
}

.sub-medium-message {
  font-family: 'Raleway';
  font-size: 3.3rem;
  line-height: 1em;
  font-weight: 800;
}

.circle-icon {
  background-color: #DC0046;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 28px;
}

.red-button {
  background-color: #DC0046;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #FFFFFF;
}

.white-button {
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #00334C;
}

@media only screen and (max-width: 767px) {
  .red-button {
    flex-direction: column;
  }  
}

.red-button:hover {
  cursor: pointer;
  color: #FFFFFF;
  text-decoration: none;
}

.tip-message {
  font-family: 'Raleway';
  font-size: 15px;
  height: 30px;
  line-height: 28px;
}

.button-download {
  background-color: black; height: 50px; width: 120px; border-radius: 7px; background-size: contain; background-repeat: no-repeat; background-position:center; 
}

.button-download-large {
  background-color: black; height: 50px; width: 150px; border-radius: 7px; background-size: contain; background-repeat: no-repeat; background-position:center; 
}

.button-download-mobile {
  background-color: black; height: 50px; width: 100%; border-radius: 7px; background-size: contain; background-repeat: no-repeat; background-position:center; 
}

.apple {
  background-image: url('./assets/app-store.png');
}

.google {
  background-image: url('./assets/google-play-store.png');
}

.button-download:hover, .button-download-large:hover {
  cursor: pointer;
}

.button-link {
  display: inline-block; width: 100%; height: 100%;
}

.large-image {
  width: 310px;
}

.small-image {
  width: 210px;
}

.red-banner {
  background-color: #DC0046;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  text-align: center;
  height: 160px;
}

.banner-message {
  font-family: 'Raleway';
  font-size: 70px;
  line-height: 160px;
}

.red-banner-mobile {
  background-color: #DC0046;
  text-align: center;
  height: 120px;
}

.banner-message-mobile {
  font-family: 'Raleway';
  font-size: 50px;
  line-height: 120px;
}

.question {
  color: #DC0046;
  font-style: italic;
}

.answer {
  color: #00334C;
}

.smaller {
  font-size: 10px;
}

.footer-link {
  text-decoration: underline;
}

.meniaga-bold {
  font-family: 'Raleway';
  font-weight: bold;
}

table, tr, td {
  border: 1px solid #00334C;
}

td {
  padding: 10px;
}

.row-term {
  width: 20%;
}

.row-definition {
  width: 80%;
}

.redirect {
  min-height: 40vh;
  padding: 30px 100px 30px 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.instagram-instruction {
  text-align: center;
  line-height:30px;
}

.ellipsis {
  background-color: black;
  color: white;
  font-size: 16px;
  padding-left:8px;
  padding-right:8px;
  padding-bottom:4px;
  padding-top:4px;
  border-radius:5px;
}

.alliance-image {
  height:140px;
  margin-right: 10px;
  padding-left: 20px;
}

.meniaga-image {
  height:30px;
  border-left:  1.5px solid #00334C;
  padding-left: 10px;
}

.meniaga-graph-image {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.alliance_form{
  font-size: 14px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  width: 90%;
  color:grey;
  padding: 14px 20px;
  margin: 8px 0;
  border: 2px solid #ccc;
  border-radius: 12px;
  outline:none;
}

.alliance_form:focus{
  box-shadow: 0 0 1pt 1pt #00334C;
}

::placeholder {
  color:#D3D3D3;
  opacity: 1; /* Firefox */
}

.alliance-footer{
  background-image: url("assets/AllianceBank//banner-web-1.png");
  padding: 0 0px 20px 100px;
}

.alliance-form-footer{
  background-image: url("assets/AllianceBank//banner-web-2.png");
  padding: 30px 100px 0px 100px;
  border-bottom: 15px solid red;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.meniaga-image-mobile {
  text-align: center;
  height: 30px;
}

.alliance-image-mobile {
  text-align: center;
  height: 120px;
}

.alliance-form-footer-mobile{
  background-image: url("assets/AllianceBank//banner-web-2.png");
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 50px 30px 50px;
}

.iframeVideo {
	height: 0px;
	padding-top: 25px;
	padding-bottom: 56.2%;
	position: relative;
}

.iframeVideo iframe {
	width: 100%;
	height: 100%;
	position: absolute;
}

@media only screen and (min-width: 768px)  {
  .referral-video {
    padding-left:40px;
  }
  
  .referral-video-title{
    margin-left:20px;
    margin-right:20px;
  }
} 

.chip {
  display: flex;
  justify-content:center;
  align-items:center;
  font-family:'Inter Tight', sans-serif;
  font-size:14px;
  line-height:21px;
  padding:10px 14px 10px 14px;
  border-radius:45px; 
  background-color:#FFE2AE; 
}

#sertai-button:hover {
  text-decoration: none;
}

.top-logo-section {
  display:flex; 
  justify-content:space-between; 
  line-height: 10vh; 
  background-color:'white'; 
  padding:0 40px 0px 40px;
}

@media only screen and (max-width: 767px) {
  .top-logo-section-mobile {
    display: flex;
    flex-direction: row;
    margin-right: -15px; 
    margin-left: -15px;
  }  

  .raya-info-text-mobile {
    text-align: center;
  }

  .raya-info-banner-mobile {
    font-family: "Inter Tight", sans-serif;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: 16px;
    padding: 10.5px 16px;
    color:#FFF;
    background-color: #21BF57;
  }

  .sub-medium-message-mobile {
    font-family: 'Raleway';
    font-size: 2.5rem;
    line-height: 1em;
    font-weight: 800;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #FFF;
}